import classnames from 'classnames';

import Image from 'components/Image/Image';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import { CarouselImageSizes } from 'lib/carouselImageSizes';
import { ImageCarouselModuleItemEntry } from 'lib/contentful';
import { getUrlFromAsset } from 'lib/contentful/utils';

import styles from './CarouselItemCard.module.scss';

const buildImageSrcs = (imageUrl: string) => {
  const src = {
    url: imageUrl,
    width: CarouselImageSizes.extraSmall,
  };
  const smallSrc = {
    url: imageUrl,
    width: CarouselImageSizes.small,
  };
  const mediumSrc = {
    url: imageUrl,
    width: CarouselImageSizes.medium,
  };
  const largeSrc = {
    url: imageUrl,
    width: CarouselImageSizes.large,
  };
  const xLargeSrc = {
    url: imageUrl,
    width: CarouselImageSizes.extraLarge,
  };

  return { largeSrc, mediumSrc, smallSrc, src, xLargeSrc };
};

type CarouselItemHorizontalCardProps = {
  carouselItem: ImageCarouselModuleItemEntry;
  className?: string;
  classNameImage?: string;
  classNameLink?: string;
  position: number;
};

const CarouselItemCard = (props: CarouselItemHorizontalCardProps) => {
  const {
    carouselItem,
    className,
    classNameImage,
    classNameLink,
    position,
  } = props;
  const { image, imageAltText, link, title } = carouselItem.fields;
  const imageUrl = getUrlFromAsset(image);
  const imageSrcs = buildImageSrcs(imageUrl);

  return (
    <div
      className={classnames(styles.root, styles.circle, className)}
      data-card-position={position}
      key={carouselItem.sys.id}
    >
      <LocalizableLink
        {...link.fields}
        className={styles.imageContainer}
        displayText={title ?? 'Category Option'}
      >
        <Image
          alt={imageAltText}
          className={classnames(styles.image, classNameImage)}
          {...imageSrcs}
        />
      </LocalizableLink>

      <div aria-hidden="true" className={styles.titleContainer}>
        {title && (
          <div className={styles.title} tabIndex={-1}>
            {title}
          </div>
        )}
        {link && (
          <LocalizableLink
            {...link.fields}
            className={classnames(styles.cardLink, classNameLink)}
            tabIndex={-1}
          />
        )}
      </div>
    </div>
  );
};

export default CarouselItemCard;

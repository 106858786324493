import classnames from 'classnames';
import { ReactElement } from 'react';

import ContentfulImage from 'components/contentfulImage/ContentfulImage';
import { generateImageSrcSet } from 'components/Image/Image';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import { Breakpoints } from 'lib/breakpoints';
import { MultiLinkBannerModuleEntry, TextColor } from 'lib/contentful';
import { getUrlFromAsset } from 'lib/contentful/utils';
import Logger from 'lib/utils/Logger';

import styles from './MultiLinkBannerModule.module.scss';

import { ContentfulModuleProps } from '../sharedTypes';

type MultiLinkBannerModuleProps = ContentfulModuleProps<
  MultiLinkBannerModuleEntry
>;

const MultiLinkBannerModule = (
  props: MultiLinkBannerModuleProps
): ReactElement | null => {
  try {
    const { className, entry } = props;
    const {
      header,
      image,
      imageAltText,
      links,
      mobileImage,
      textColor = TextColor.Light,
    } = entry.fields;
    const desktopImageUrl = getUrlFromAsset(image);
    const mobileImageUrl = mobileImage
      ? getUrlFromAsset(mobileImage)
      : desktopImageUrl;

    const textColorClassName =
      textColor === TextColor.Light ? styles.whiteText : styles.blackText;

    return (
      <div
        className={classnames(styles.root, className, textColorClassName)}
        {...props.dataAttributes}
      >
        <ContentfulImage
          alt={imageAltText}
          className={styles.image}
          imageContainerClassName={styles.imageContainer}
          mediumSrc={generateImageSrcSet({
            url: desktopImageUrl,
            width: Breakpoints.widthLarge,
          })}
          smallSrc={generateImageSrcSet({
            url: desktopImageUrl,
            width: Breakpoints.widthMedium,
          })}
          src={generateImageSrcSet({
            url: mobileImageUrl,
            width: Breakpoints.widthSmall,
          })}
        />
        <div className={styles.contentContainer}>
          <div className={styles.header}>{header}</div>

          <div className={styles.linkContainer}>
            {links.map(link => (
              <LocalizableLink
                {...link.fields}
                className={styles.link}
                key={link.sys.id}
              />
            ))}
          </div>
        </div>
      </div>
    );
  } catch (error) {
    Logger.warn(
      `Error rendering MultiLinkBannerModule - props: ${JSON.stringify(props)}`
    );
    return null;
  }
};

export default MultiLinkBannerModule;
